import React from 'react';

const VoteOptions = ({ data, onVoting, voteClosed }) => {
  return (
    <>
      {data?.voting_options && data?.voting_options?.length
        ? data?.voting_options?.map((item, key) => {
            return (
              <div
                key={item.id}
                className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3"
              >
                <p className="text-blue text-16 font-weight-bold">
                  Opción {key + 1}:
                </p>
                <div className="w-100 border-none bg-transparent text-center">
                  <div className="card-incidences h-100 px-4 py-3">
                    <p className="text-16 text-blue font-weight-bold mb-3 text-capitalize">
                      {item?.title}
                    </p>
                    <button
                      className="btn border-none bg-main-blue text-white font-weight-bold text-lg px-4  rounded-lg"
                      data-toggle="modal"
                      disabled={voteClosed}
                      data-target={`#modal-voting-${item?.id}`}
                    >
                      Votar
                    </button>
                    <div id={`modal-voting-${item?.id}`} className="modal fade">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center mt-4 mb-1">
                            <p className="text-xs font-roboto text-main-grey-dark">
                              Confirmar votación, estas votando por:
                            </p>
                            <h4 className="text-blue font-weight-bold">
                              {item?.title}
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-between border-none">
                            <button
                              type="button"
                              className="col-6 btn rounded-lg  bg-main-grey-light text-main-grey text-lg font-weight-bold my-2"
                              data-dismiss="modal"
                            >
                              Volver
                            </button>
                            <button
                              onClick={() => onVoting(item)}
                              type="button"
                              className="col-6 btn rounded-lg bg-main-blue text-white text-lg font-weight-bold my-2"
                              data-dismiss="modal"
                            >
                              Confirmar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ''}
    </>
  );
};

export default VoteOptions;
