import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import Loader from '../../components/Loader';
moment.locale('es-MX');

const apiVote = `${servicePath}/votes`;

const Votes = () => {
  let history = useHistory();
  const token = useSelector((state) => state.jwt);
  const address = useSelector((state) => state.address);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [filterStatus, setFilterStatus] = useState();

  async function fetchData() {
    setIsLoaded(true);
    try {
      let queryParams = [{ addresses: address }];

      if (filterStatus) {
        const query = [{ status_in: [filterStatus] }];
        queryParams.push(...query);
      }

      if (filterDate) {
        const startOfMonth = moment(filterDate).startOf('month').toISOString();
        const query = [{ startDate_gte: startOfMonth }];
        queryParams.push(...query);
      } else {
        const startOfMonth = moment().startOf('month').toISOString();
        const query = [{ startDate_gte: startOfMonth }];
        queryParams.push(...query);
      }

      queryParams = qs.stringify({
        _where: queryParams,
      });

      const response = await axios.get(
        `${apiVote}?${queryParams}&_sort=startDate:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
      setIsLoaded(false);
    } catch (error) {
      Alert.error({
        title: 'Hubo un problema, intentelo mas tarde.',
      });
      history.push('/');
      setIsLoaded(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [filterDate, filterStatus, address]);

  return (
    <Section title="Votaciones">
      {isLoaded ? (
        <Loader />
      ) : (
        <div className="pt-3">
          <div className="container">
            <div className="d-flex new-search input pr-0">
              <div className="col-6 pe-2 d-flex align-items-center">
                <input
                  type="month"
                  placeholder="date"
                  className="form-control border-0 w-100 bg-beige h-100"
                  value={filterDate || moment().format('YYYY-MM')}
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>
              <div className="col-6 ps-2 d-flex align-items-center">
                <select
                  name="select"
                  className="form-control border-0 w-100 bg-beige text-blue"
                  onChange={(e) => setFilterStatus(e.target.value)}
                  value={filterStatus}
                >
                  <option value="" selected>
                    Todo
                  </option>
                  <option value="new">Pendiente</option>
                  <option value="active">Activa</option>
                  <option value="completed">Cerrada</option>
                </select>
              </div>
            </div>
          </div>
          <div className="container my-4">
            <div className="col-8 mx-auto text-center">
              <p className="mb-0 text-main-blue text-base font-roboto">
                Selecciona alguna de las
              </p>
              <p className="text-main-blue text-base font-roboto">
                Sesiones para votar
              </p>
            </div>
            <div className="col-12 my-4">
              <div className="row align-items-stretch gap-y-3 ">
                {data && data?.length ? (
                  data?.map((item) => {
                    return (
                      <Link
                        to={`/vote/${item.id}`}
                        className={`col-12 col-sm-12 col-md-6 col-xl-4 mb-3`}
                        key={item.id}
                      >
                        <div
                          className={`card-incidences h-100 px-2 py-3 ${
                            item?.status === 'new' ? 'bg-grey' : ''
                          }`}
                        >
                          <div className="row align-items-center justify-content-center">
                            <div className="col-3 text-center">
                              <img
                                className={`small-icon mx-auto ${
                                  item?.status !== 'active' &&
                                  'grayscale-opacity'
                                }`}
                                width="70"
                                height="70"
                                src={`/assets/icons/votaciones.svg`}
                                alt="img"
                              />
                            </div>
                            <div className="col-7">
                              <p
                                className={`font-weight-bold pb-2 text-capitalize ${
                                  item?.status === 'new'
                                    ? 'text-gray'
                                    : 'text-blue'
                                }`}
                              >
                                {item?.title}
                              </p>
                              <p
                                className={`comments font-weight-bold mb-0  ${
                                  !item?.isActive ? 'text-gray' : 'text-blue'
                                }`}
                              >
                                <span>Inicia: </span>
                                <span className="text-uppercase">
                                  {moment(item?.startDate).format(
                                    'DD/MMM/YY hh:mm A'
                                  )}
                                </span>
                              </p>
                              <p
                                className={`comments font-weight-bold mb-0  ${
                                  !item?.isActive ? 'text-gray' : 'text-blue'
                                }`}
                              >
                                <span>Termina: </span>
                                <span className="text-uppercase">
                                  {moment(item?.endDate).format(
                                    'DD/MMM/YY hh:mm A'
                                  )}
                                </span>
                              </p>
                              <p className={`comments font-weight-bold mb-0`}>
                                <span>
                                  Categoria: {item?.survey_category?.name}
                                </span>
                              </p>
                              <p className={`comments font-weight-bold mb-0`}>
                                <span>Estado: </span>
                                {item?.status === 'active' ? (
                                  <span className="text-green">Activa</span>
                                ) : item?.status === 'completed' ? (
                                  <span className="text-danger">Cerrada</span>
                                ) : (
                                  <span className="text-primary">
                                    Pendiente
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-2 text-center">
                              <i
                                className={`small-icon icon-back ${
                                  item?.status !== 'active'
                                    ? 'grayscale-opacity'
                                    : ''
                                }`}
                                style={{ transform: 'rotate(180deg)' }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <p className="text-main-blue text-base font-roboto font-weight-bold text-center mx-auto">
                    No hay sesiones disponibles para votar
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Section>
  );
};

export default Votes;
