import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
moment.locale('es-MX');
import Section from '../../components/layouts/Section';
import { useParams, useHistory } from 'react-router-dom';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import VoteSessions from '../../components/votes/sessions';
import VoteOptions from '../../components/votes/options';

const apiVote = `${servicePath}/votes`;
const apiSessions = `${servicePath}/voting-sessions`;
const apiResponses = `${servicePath}/voting-residents`;

const VoteDetails = () => {
  const { id } = useParams();
  let history = useHistory();
  const token = useSelector((state) => state.jwt);
  const resident = useSelector((state) => state.resident);
  const address = useSelector((state) => state.address);
  const user = useSelector((state) => state.user);
  const condominium = useSelector((state) => state.condominium);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [selectSession, setSelectSession] = useState();
  const [timeLeft, setTimeLeft] = useState('');
  const [voteClosed, setVoteClosed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCheckin, setModalCheckin] = useState(false);
  const [errorVerify, setErrorVerify] = useState();

  const checkActive = async () => {
    try {
      const response = await axios.post(
        `${apiVote}/verifyAccess`,
        {
          resident: resident.id,
          unit: address,
          vote: data.vote.id,
          status: data.vote.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data.checkin && data?.vote.status === 'active') {
        setModalCheckin(true);
        setActiveSession(false);
      }
      if (data?.vote.status !== 'new') {
        setActiveSession(true);
      }

      setLoading(false);
    } catch (error) {
      if (error.response?.status === 422) {
        setErrorVerify(error.response.data.message);
      } else {
        setErrorVerify('No se puede procesar la solicitud');
      }
      setModalOpen(true);
      setLoading(false);
    }
  };
  const calculateTimeLeft = () => {
    const now = moment();
    const end = moment(data?.vote.endDate);

    if (now.isAfter(end)) {
      setVoteClosed(true);
      setTimeLeft('00:00:00');
    } else {
      const diff = end.diff(now);
      const duration = moment.duration(diff);

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimeLeft(
        `${days > 0 ? days + 'd ' : ''}${hours
          .toString()
          .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }
  };
  const goSummary = (item) => {
    history.push(`/voting/summary/${item}`);
  };

  async function fetchData() {
    setLoading(true);
    try {
      const response = await axios.get(`${apiSessions}?vote=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const voteData = await axios.get(`${apiVote}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responses = await axios.get(
        `${apiResponses}?resident=${resident.id}&vote=${voteData.data.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVoteClosed(voteData.data.status === 'completed');

      const val = {
        vote: voteData.data,
        sessions: response.data,
        responses: responses.data,
      };
      setData(val);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la votación',
      });
      setLoading(false);
    }
  }
  const onCheckin = async () => {
    try {
      await axios.post(
        `${apiVote}/checkin`,
        {
          resident: resident.id,
          unit: address,
          vote: data.vote.id,
          check: true,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setModalCheckin(false);
      setActiveSession(true);
    } catch (error) {
      Alert.error({
        title: 'Error al procesar el check-in',
      });
      history.goBack();
    }
  };
  const onVoting = async (option) => {
    try {
      const values = {
        resident: resident.id,
        vote: data.vote.id,
        unit: address,
        option: option.id,
        session: option.voting_session,
        condominium,
      };
      await axios.post(`${apiVote}/voting`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectSession();
      history.push(`/voting/summary/${option.voting_session}`);
    } catch (error) {
      Alert.error({
        title: 'Error al procesar el voto',
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      checkActive();
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data]);

  return (
    <Section
      title="Votaciones"
      withBack={true}
      noBorder={true}
      cancelTo="/votes"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="pt-3">
          {data ? (
            <div className="container my-4">
              <div className="col-12 ">
                <p className="mb-0 text-main-blue text-base font-roboto font-weight-bold">
                  {!selectSession ? data?.vote.title : selectSession?.title}
                </p>
                <p className="mb-0 text-main-blue text-base font-roboto font-weight-bold">
                  {moment(data?.vote.startDate).format('D MMMM YYYY, h:mm a')}
                </p>
                {!selectSession ? (
                  <>
                    <p className="mb-0  text-base font-roboto ">
                      <span className="font-weight-bold text-main-blue">
                        Descripción:{' '}
                      </span>
                      <span className="text-main-grey-dark">
                        {data?.vote.description}
                      </span>
                    </p>
                    <p className="my-4 text-main-blue text-base font-roboto ">
                      Selecciona el punto de acuerdo para dejar tu voto.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-16">
                      {voteClosed ? (
                        <span className="text-danger">Cerrada</span>
                      ) : (
                        <>
                          <span className="text-mint">Activa:</span> te quedan{' '}
                          <span className="text-mint">{timeLeft}</span>
                          <b> para votar</b>
                        </>
                      )}
                    </p>
                    <p className="mb-0 text-16">
                      <span className="font-weight-bold text-blue">
                        Descripción:{' '}
                      </span>
                      <span>{selectSession?.description}</span>
                    </p>
                    {selectSession?.link && (
                      <a
                        href={selectSession?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-main-blue-light text-underline"
                      >
                        Revisa valores y las mejores opciones a considerar.
                      </a>
                    )}
                  </>
                )}
              </div>
              <div className="col-12 my-4">
                <div className="row align-items-stretch gap-y-3">
                  {!selectSession ? (
                    <VoteSessions
                      data={data}
                      activeSession={activeSession}
                      setSelectSession={setSelectSession}
                      goSummary={goSummary}
                      voteClosed={voteClosed}
                    />
                  ) : (
                    <VoteOptions
                      data={selectSession}
                      onVoting={onVoting}
                      voteClosed={voteClosed}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p className="text-main-blue text-base font-roboto font-weight-bold text-center mx-auto p-5">
              No se pudo cargar la sesión, intentelo más tarde
            </p>
          )}
          {modalOpen && (
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center mt-4 mb-1">
                    <p className="text-xs font-roboto text-main-grey-dark">
                      Operación no permitida
                    </p>
                    <h4 className="text-blue font-weight-bold">
                      {errorVerify}
                    </h4>
                  </div>
                  <div className="modal-footer justify-content-center border-none">
                    <button
                      type="button"
                      className="col-6 btn rounded-lg  bg-main-grey-light text-main-grey text-lg font-weight-bold my-2"
                      onClick={() => history.goBack()}
                    >
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {modalCheckin && (
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {data.vote.canCheckin ? (
                    <>
                      <div className="modal-body text-center mt-4 mb-1">
                        <p className="text-xs font-roboto text-main-grey-dark">
                          Estas por realizar check-in
                        </p>
                        <h4 className="text-blue font-weight-bold">
                          ¿Deseas continuar?
                        </h4>
                      </div>
                      <div className="modal-footer justify-content-center border-none">
                        <button
                          type="button"
                          className="col-6 btn rounded-lg  bg-main-grey-light text-main-grey text-lg font-weight-bold my-2"
                          onClick={() => history.goBack()}
                        >
                          Volver
                        </button>
                        <button
                          type="button"
                          className="col-6 btn rounded-lg bg-main-blue text-white text-lg font-weight-bold my-2"
                          onClick={() => onCheckin()}
                        >
                          Confirmar
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="modal-body text-center mt-4 mb-1">
                        <p className="text-sm font-roboto text-main-grey-dark">
                          Contacta a tu administrador.
                        </p>
                        <h4 className="text-blue font-weight-bold">
                          Completa el check-in para poder votar.
                        </h4>
                      </div>
                      <div className="modal-footer justify-content-center border-none">
                        <button
                          type="button"
                          className="col-6 btn rounded-lg  bg-main-grey-light text-main-grey text-lg font-weight-bold my-2"
                          onClick={() => history.goBack()}
                        >
                          Volver
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Section>
  );
};
export default VoteDetails;
