import React, { useEffect } from 'react';
import { Camera } from '@capacitor/camera';
import Routes from './Routes';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import './App.css';

const App = () => {
  useEffect(() => {
    async function requestPermissions() {
      try {
        await Camera.requestPermissions();
      } catch (error) {
        console.error('Error requesting camera permissions:', error);
      }
    }

    requestPermissions();
  }, []);

  return <Routes />;
};

export default App;
