import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/auth/actions';
import SelectAddress from './SelectAddress';

/* class Navbar extends Component {
  render() { */
const Navbar = (jwt) => {
  const logout = () => {
    jwt.logoutUserAction(null, null, useHistory);
  };
  return (
    <div className={`ba-navbar top-60 pt-4`}>
      {/*     <div className="ba-navbar-user">
        <div className="w-50">
          <div className="onda"></div>{" "}
          <div className="menu-close">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/menu/cerrar_menu.svg"}
            />
          </div>
        </div>
        <div className="w-50 text-right notification">
           <i className="large-icon icon-notificaciones"></i>
        </div>

      </div> */}
      <div className="ba-add-balance-title d-flex align-items-center p-0">
        <SelectAddress />
      </div>
      {/* <div className="ba-add-balance-title style-two p-0">
        <div className="input-group m-0 p-0 search">
          <input
            type="text"
            placeholder="Buscar"
            className="form-control border-0 p-3"
          />
          <div className="input-group-append p-0 ">
            <span className="input-group-text p-1  border-0">
              <i className="small-icon icon-search p-0" />
            </span>
          </div>
        </div>
      </div> */}
      <div className="ba-main-menu border-0 m-0">
        <ul className="ul-main" id="accordion">
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#mi-cuenta"
            >
              <i className="small-icon mi-cuenta mr-2" />
              Mi cuenta
            </a>
            <div
              id="mi-cuenta"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/user-setting'}>Mi perfil</Link>
                </li>
                {/* <li>
                  <Link to={"/user-verification"}>Datos de pago</Link>
                </li> */}
              </ul>
            </div>
          </li>
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#pagos"
            >
              <i className="small-icon icon-pagos mr-2" />
              Pagos
            </a>
            <div
              id="pagos"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/payments/history'}>Historial de pagos</Link>
                </li>
                <li>
                  <Link to={'/payments'}>Realizar pagos</Link>
                </li>
                <li>
                  <Link to={'/payments/add-card'}>Agregar método de pago</Link>
                </li>
                <li>
                  <Link to={'/payments/payment-methods'}>Métodos de pagos</Link>
                </li>
                {/* <li>
                  <Link to={"#"}>Realizar pagos</Link>
                </li>
                <li>
                  <Link to={"/transaction-details"}>Detalles de pago</Link>
                </li> */}
              </ul>
            </div>
          </li>
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#reservaciones"
            >
              <i className="small-icon icon-reservaciones mr-2" />
              Reservaciones
            </a>
            <div
              id="reservaciones"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/reservations'}>Ver reservaciones</Link>
                </li>
                <li>
                  <Link to={'/reservations/create'}>Nueva reservación</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#noticias"
            >
              <i className="small-icon icon-noticias mr-2" />
              Noticias
            </a>
            <div
              id="noticias"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/news'}>Ver noticias</Link>
                </li>
                {/* <li>
                  <Link to={"/news/create"}>Nueva noticia</Link>
                </li> */}
              </ul>
            </div>
          </li>
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#incidencias"
            >
              <i className="small-icon icon-incidencias mr-2" />
              Incidencias
            </a>
            <div
              id="incidencias"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/incidences'}>Ver incidencias</Link>
                </li>
                <li>
                  <Link to={'/incidences/create'}>Nueva incidencia</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link
              to={'/invitations'}
              className="d-flex align-items-center bg-transparent border-0 no-after"
              style={{ padding: '.75rem 1.60rem' }}
            >
              <i className="small-icon icon-invitaciones mr-2" />
              Invitaciones
            </Link>
          </li>
          <li>
            <Link
              to={'/offers'}
              className="d-flex align-items-center bg-transparent border-0 no-after"
              style={{ padding: '.75rem 1.60rem' }}
            >
              <i className="small-icon icon-offer mr-2" />
              Ofertas
            </Link>
          </li>
          {/* <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#invitaciones"
            >
              <i className="small-icon icon-invitaciones mr-2" />
              Invitaciones
            </a>
            <div
              id="invitaciones"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={"#"}>Ver invitaciones</Link>
                </li>
                <li>
                  <Link to={"#"}>Hacer Invitación</Link>
                </li>
              </ul>
            </div>
          </li> */}
          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#votaciones"
            >
              <i className="small-icon icon-votaciones mr-2" />
              Votaciones
            </a>
            <div
              id="votaciones"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/votes'}>Ver votaciones</Link>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#surveys"
            >
              <i className="small-icon icon-encuestas mr-2" />
              Encuestas
            </a>
            <div
              id="surveys"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={'/surveys'}>Ver Encuestas</Link>
                </li>
              </ul>
            </div>
          </li>

          {/* <li>
            <a
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
              data-toggle="collapse"
              href="#transparencia"
            >
              <i className="small-icon icon-transparencia mr-2" />
              Transparencia
            </a>
            <div
              id="transparencia"
              className="card-body collapse pt-0 pl-5 pb-0"
              data-parent="#accordion"
            >
              <ul className="m-0 p-0">
                <li>
                  <Link to={"/saving"}>Ahorros</Link>
                </li>
                <li>
                  <Link to={"/user-verification"}>Verificar usuario</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contacto</Link>
                </li>
                <li>
                  <Link to={"/notification"}>Notificaciones</Link>
                </li>
                <li>
                  <Link to={"/about"}>Sobre Nosotros</Link>
                </li>
                <li>
                  <Link to={"/bill-pay"}>Facturación</Link>
                </li>
                <li>
                  <Link to={"/blog"}>Blog</Link>
                </li>
                <li>
                  <Link to={"/blog-details"}>Blog detalles</Link>
                </li>
                <li>
                  <Link to={"/carts"}>Carts</Link>
                </li>
              </ul>
            </div>
            <Link
              to={"#"}
              className="card-header collapsed d-flex align-items-center bg-transparent border-0"
            >
              <i className="small-icon icon-transparencia mr-2" />
              Transparencia
            </Link>
          </li> */}
          <li>
            <button
              className="my-2 btn bg-transparent mx-auto text-center"
              onClick={(e) => logout()}
            >
              Cerrar sesión
            </button>
          </li>
        </ul>
        {/* <ul>
            <li>
              <Link to={"/"}>Bankapp Display</Link>
            </li>

            <li>
              <Link to={"/all-pages"}>Pages</Link>
            </li>
            <li>
              <Link to={"/components"}>Components</Link>
            </li>
            <li>
              <Link to={"/carts"}>My Cart</Link>
            </li>
            <li>
              <Link to={"/user-setting"}>Setting</Link>
            </li>
            <li>
              <Link to={"/notification"}>Notification</Link>
            </li>
            <li>
              <Link to={"/logout"}>Logout</Link>
            </li>
          </ul>
          <Link className="btn btn-purple" to={"/user-setting"}>
            View Profile
          </Link> */}
      </div>
    </div>
  );
};
// }
const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
    user: state.user,
    history: useHistory,
  };
};

export default connect(mapStateToProps, { logoutUserAction: logoutUser })(
  Navbar
);
