import React from 'react';

const VoteSessions = ({
  data,
  activeSession,
  setSelectSession,
  goSummary,
  voteClosed,
}) => {
  return (
    <>
      {data?.sessions &&
        data?.sessions.length > 0 &&
        data?.sessions.map((session, key) => {
          const foundResponse = data.responses.some(
            (response) => response.voting_session?.id === session.id
          );
          const canOpenSession =
            data.vote.status === 'completed' || foundResponse || session.isOpen;

          return (
            <button
              className={`card-incidences h-100 px-2 py-3 border-0 text-left ${
                !activeSession || foundResponse || voteClosed
                  ? 'bg-main-grey-light'
                  : ''
              }`}
              key={key}
              onClick={() =>
                foundResponse || voteClosed
                  ? goSummary(session.id)
                  : setSelectSession(session)
              }
              disabled={!activeSession || !canOpenSession}
              style={{
                width: '100%',
                cursor: activeSession ? 'pointer' : 'not-allowed',
              }}
            >
              <div className="row align-items-center justify-content-center">
                <div className="col-3 text-center">
                  <img
                    className={`small-icon mx-auto ${
                      !activeSession || voteClosed ? 'filter-blue-to-gray' : ''
                    }`}
                    width="70"
                    height="70"
                    src={`/assets/img/icon/votaciones.svg`}
                    alt="img"
                  />
                </div>
                <div className="col-6">
                  <p
                    className={`text-base font-weight-bold font-roboto ${
                      !activeSession || voteClosed
                        ? 'text-main-grey'
                        : 'text-main-blue'
                    }`}
                  >
                    {session?.title}
                    {foundResponse && data.vote.status === 'active' && (
                      <p className="text-xs font-roboto my-2 text-green">
                        Completada
                      </p>
                    )}
                  </p>
                  <p
                    className={`text-xs font-roboto my-2 ${
                      !activeSession || voteClosed
                        ? 'text-main-grey'
                        : 'text-main-grey-dark'
                    }`}
                  >
                    {session?.description}
                  </p>
                  {data.vote.status === 'active' ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        className={`text-xs font-roboto my-2 ${
                          !activeSession || voteClosed
                            ? 'text-main-grey'
                            : 'text-main-grey-dark'
                        }`}
                      >
                        Estatus:{' '}
                        {session.isOpen ? (
                          <span className="text-green">Activa</span>
                        ) : (
                          <span className="text-danger">Cerrada</span>
                        )}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-2 text-center">
                  <i
                    className={`small-icon icon-next ${
                      !activeSession || voteClosed ? 'filter-blue-to-gray' : ''
                    }`}
                  ></i>
                </div>
              </div>
            </button>
          );
        })}
    </>
  );
};
export default VoteSessions;
