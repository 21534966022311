import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import Loader from '../../components/Loader';

const apiVote = `${servicePath}/votes`;
const apiSessions = `${servicePath}/voting-sessions`;
const apiResponses = `${servicePath}/voting-residents`;
const mainColors = ['#3927ab', '#fed65a', '#4741eb'];

const centerTextPlugin = {
  id: 'centerText',
  beforeDraw(chart) {
    const { ctx, width, height } = chart;
    const { text } = chart.config.options.plugins.centerText;

    ctx.save();
    ctx.font = '60px "Roboto", sans-serif';
    ctx.fillStyle = '#2b2baf';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const textX = width / 2;
    const textY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    ctx.fillText(text, textX, textY);
    ctx.restore();
  },
};

Chart.register(centerTextPlugin);

const VoteSummary = () => {
  const { id } = useParams();
  const history = useHistory();
  const token = useSelector((state) => state.jwt);
  const resident = useSelector((state) => state.resident);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState();
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [timeLeft, setTimeLeft] = useState('');
  const [voteClosed, setVoteClosed] = useState(false);
  const [centerText, setCenterText] = useState('');
  const [selectItem, setSelectItem] = useState(null);
  const [topOption, setTopOption] = useState(null);

  const calculateTimeLeft = () => {
    const now = moment();
    const end = moment(data?.vote.endDate);

    if (now.isAfter(end)) {
      setVoteClosed(true);
      setTimeLeft('00:00:00');
    } else {
      const diff = end.diff(now);
      const duration = moment.duration(diff);

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimeLeft(
        `${days > 0 ? days + 'd ' : ''}${hours
          .toString()
          .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }
  };
  const generateColors = (count) => {
    const similarColors = [];
    const hueStep = 360 / count;

    for (let i = 0; i < count; i++) {
      const hue = (i * hueStep) % 360;
      const saturation = 70 + Math.random() * 10;
      const lightness = 50 + Math.random() * 10;
      similarColors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }

    return similarColors;
  };
  const generateChartDataAndOptions = () => {
    const options = data.voting_options;

    const chartData = {
      labels: options.map((option) => option.title),
      datasets: [
        {
          data: options.map((option) =>
            data.voting_residents
              .filter((resident) => resident.voting_option === option.id)
              .reduce((sum, resident) => sum + resident.votingValue, 0)
          ),
          backgroundColor:
            options.length <= mainColors.length
              ? mainColors.slice(0, options.length)
              : [
                  ...mainColors,
                  ...generateColors(options.length - mainColors.length),
                ],
        },
      ],
    };

    const chartOptions = {
      cutout: '70%',
      plugins: {
        centerText: {
          text: centerText,
        },
        legend: {
          display: false,
        },
      },
      onClick(e, elements) {
        if (elements.length > 0) {
          const index = elements[0].index;
          const value = chartData.datasets[0].data[index];
          setSelectItem(data.voting_options[index]);
          setCenterText(`${value}%`);
        }
      },
    };
    return { chartData, chartOptions };
  };
  const getMaxVotingOption = (responses) => {
    const votingValueMap = {};

    let maxOption = null;

    responses.forEach((response) => {
      const { id } = response.voting_option;
      const { votingValue } = response;

      if (!votingValueMap[id]) {
        votingValueMap[id] = {
          total: 0,
          count: 0,
          option: response.voting_option,
        };
      }

      votingValueMap[id].total += votingValue;
      votingValueMap[id].count += 1;

      if (!maxOption || votingValueMap[id].total > maxOption.total) {
        maxOption = votingValueMap[id];
      }
    });

    return maxOption;
  };

  async function fetchData() {
    setIsLoaded(true);
    try {
      const sessionData = await axios.get(`${apiSessions}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const voteData = await axios.get(
        `${apiVote}/${sessionData.data.vote.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVoteClosed(voteData.data.status === 'completed');
      if (voteData.data.status === 'completed') {
        const responsesData = await axios.get(
          `${apiResponses}?vote=${voteData.data.id}?voting_session=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTopOption(getMaxVotingOption(responsesData.data));
      }

      const residentVote = sessionData.data.voting_residents.find(
        (item) => item.resident === resident.id
      );
      const residentOption = sessionData.data.voting_options.find(
        (item) => item.id === residentVote.voting_option
      );

      setData({
        ...sessionData.data,
        vote: voteData.data,
        residentVote: {
          date: residentVote.date,
          option: residentOption,
        },
      });
      setIsLoaded(false);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la sesión',
      });
      setIsLoaded(false);
    }
  }

  useEffect(() => {
    if (data) {
      const percent = Math.round(
        (data.vote.voted_addresses.length / data.vote.addresses.length) * 100
      );

      setLoadingPercent(percent);
    }
  }, [data]);

  useEffect(() => {
    if (data && !voteClosed) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data, voteClosed]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);
  return (
    <Section title="Votaciones" cancelTo="/votes">
      {isLoaded ? (
        <Loader />
      ) : (
        <div className="container my-4" style={{ paddingBottom: '100px' }}>
          {data ? (
            <>
              <div className="col-12">
                <div className="text-center my-4">
                  <h3 className="mt-3 mb-1 text-main-grey-dark text-base font-roboto">
                    {data.title}
                  </h3>
                </div>
                <div className="card-incidences h-100 px-2 py-3">
                  <p className="mb-1 text-main-blue text-base font-roboto font-weight-bold text-center">
                    {data?.residentVote.option.title}
                  </p>
                  <p className="mb-0 text-main-grey-dark text-base font-roboto text-center">
                    {moment(data?.residentVote.date).format(
                      'D MMMM YYYY [a las] h:mm a'
                    )}
                  </p>
                </div>
              </div>

              <div className="col-12 my-4">
                <div className="row align-items-stretch gap-y-3 justify-content-center">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-4 text-center">
                    {voteClosed ? (
                      <>
                        <p className="font-weight-bold">
                          <span className="text-blue text-lg">
                            Resultado de la votación
                          </span>
                        </p>
                        <div className="text-center mb-4">
                          <img
                            className={` mx-auto`}
                            width="100"
                            height="100"
                            src={`/assets/img/icon/Votaciones_aprobado.svg`}
                            alt="img"
                          />
                        </div>
                        <p className="mb-1 mt-2 text-main-grey-dark text-base font-roboto text-center">
                          {topOption.option.title}
                        </p>
                        <p className=" mb-0 text-main-blue text-lg font-roboto  text-center font-weight-bold">
                          ({topOption.count} Votos)
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="font-weight-bold">
                          <span className="text-blue text-lg">Resultados</span>
                        </p>
                        <div>
                          {(() => {
                            const { chartData, chartOptions } =
                              generateChartDataAndOptions();

                            return (
                              <Doughnut
                                data={chartData}
                                options={chartOptions}
                              />
                            );
                          })()}
                        </div>
                        {selectItem !== null ? (
                          <>
                            <p className="mb-1 mt-2 text-main-grey-dark text-base font-roboto text-center">
                              {selectItem.title}
                            </p>
                            <p className=" mb-0 text-main-blue text-lg font-roboto  text-center font-weight-bold">
                              (
                              {
                                data.voting_residents.filter(
                                  (resident) =>
                                    resident.voting_option === selectItem.id
                                ).length
                              }{' '}
                              Votos)
                            </p>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-12 mt-2 text-center">
                    <p className="text-blue text-lg text-center font-weight-bold">
                      % de Votación
                    </p>
                    <div className="d-flex justify-content-center">
                      <div
                        className="progress"
                        style={{
                          width: '300px',
                          height: '25px',
                          borderRadius: '19px ',
                        }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${loadingPercent}%`,
                            backgroundColor: '#3927ab',
                          }}
                          aria-valuenow={loadingPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <p className="text-16">
                      {loadingPercent}% ({data?.vote.voted_addresses.length}{' '}
                      votos de {data?.vote?.addresses.length})
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>No hay datos disponibles para mostrar.</p>
          )}
        </div>
      )}
      <div className="footer-pay bg-white d-flex justify-content-center text-center mt-4">
        <div className="mt-4">
          <p className="text-16">
            {voteClosed ? (
              <span className="text-danger">Cerrada</span>
            ) : (
              <span className="text-mint">
                Abierta: ({timeLeft} para cerrar)
              </span>
            )}
          </p>
          <button
            onClick={() => history.goBack()}
            className="text-lg bg-main-blue text-white font-weight-bold font-roboto px-5 py-3 d-inline-block mx-auto rounded-lg "
          >
            Volver
          </button>
        </div>
      </div>
    </Section>
  );
};
export default VoteSummary;
